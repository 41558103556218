import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { themes } from "~/themes/themes";

type ThemeName = keyof typeof themes;

interface AppContextType {
  isUserMobile?: boolean;
  currentTheme?: ThemeName;
  theme?: (typeof themes)[ThemeName];
  openCreditModal: boolean;
  setTheme: (themeName: ThemeName) => void;
  setMobile: (isMobile: boolean) => void;
  setOpenCreditModal: (isOpen: boolean) => void;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppContextProvider");
  }
  return context;
};

export default function AppContextProvider({
  children,
  isUserMobile,
  initialTheme = "light",
}: {
  children: ReactNode;
  isUserMobile?: boolean;
  initialTheme?: ThemeName;
}) {
  const [currentTheme, setCurrentTheme] = useState<ThemeName>(initialTheme);
  const [openCreditModal, setOpenCreditModal] = useState(false);
  const [userIsMobile, setUserMobile] = useState(false);

  // Apply theme to :root when currentTheme changes
  useEffect(() => {
    try {
      // Get the theme object
      const theme = themes[currentTheme];
      const root = document.documentElement;

      // Apply the theme class to html element
      if (currentTheme === "dark") {
        root.classList.add("dark-theme");
        root.classList.remove("light-theme");
      } else {
        root.classList.add("light-theme");
        root.classList.remove("dark-theme");
      }

      // Instead of directly setting CSS variables in the callback,
      // create a function to update them
      const updateThemeVariables = () => {
        // Set color variables
        Object.entries(theme.colors).forEach(([key, value]) => {
          root.style.setProperty(`--color-${key}`, value);
        });

        // Set padding variables
        Object.entries(theme.padding).forEach(([key, value]) => {
          root.style.setProperty(`--padding-${key}`, value);
        });

        // Set font size variables
        Object.entries(theme.fontSizes).forEach(([key, value]) => {
          root.style.setProperty(`--font-size-${key}`, value);
        });

        // Set border radius variables
        Object.entries(theme.borderRadius).forEach(([key, value]) => {
          root.style.setProperty(`--border-radius-${key}`, value);
        });

        // Set font family variables
        root.style.setProperty("--font-family", theme.fontFamily);
        root.style.setProperty("--font-family-bold", theme.fontFamilyBold);
      };

      // Use setTimeout to ensure the theme changes are processed after React's updates
      setTimeout(updateThemeVariables, 0);
    } catch (error) {
      console.error("Error applying theme:", error);
    }
  }, [currentTheme]);

  const changeTheme = (themeName: ThemeName) => {
    setCurrentTheme(themeName);

    // Directly update the theme-color meta tag
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    const themeColor =
      themeName === "dark"
        ? themes.dark.colors.background
        : themes.light.colors.background;

    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", themeColor);
    } else {
      // If the meta tag doesn't exist, create it
      const meta = document.createElement("meta");
      meta.name = "theme-color";
      meta.content = themeColor;
      document.head.appendChild(meta);
    }

    // Save preference to localStorage
    try {
      localStorage.setItem("theme", themeName);
    } catch (e) {
      console.warn("Could not save theme preference:", e);
    }
  };

  const makeMobile = (isMobile: boolean) => {
    setUserMobile(isMobile);
  };

  const toggleCreditModal = (isOpen: boolean) => {
    setOpenCreditModal(isOpen);
  };

  return (
    <AppContext.Provider
      value={{
        isUserMobile: userIsMobile,
        currentTheme,
        theme: themes[currentTheme],
        openCreditModal,
        setTheme: changeTheme,
        setMobile: makeMobile,
        setOpenCreditModal: toggleCreditModal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
